import React, { useState } from "react";
import {
  IResourceComponentsProps,
  useNavigation,
  useGetIdentity} from "@pankod/refine-core";
import {
  Grid,
  Button,
  useAutocomplete,
  Autocomplete,
  TextField,
} from "@pankod/refine-mui";
import { IGroup, ISubGroup } from "interfaces";

export const StudentGroupList: React.FC<IResourceComponentsProps> = () => {


const { autocompleteProps: GroupAutoCompleteProps } = useAutocomplete<IGroup>({
    resource: "teachergroup",
});
const { push,show } = useNavigation()
const [group, setGroup] = useState<IGroup | null>(null)
const { autocompleteProps: SubGroupAutoCompleteProps } = useAutocomplete<ISubGroup>({
    resource: "teachergroup/subgroups",
    filters: [{
        field: "groupId",
        value: group?.id,
        operator: "eq"
    }],
});



const { data: user } = useGetIdentity()


const [subGroup, setSubGroup] = useState<ISubGroup | null>(null)

return (
    <Grid
        container
        padding={4}
        gap={5}
        spacing={{ xs: 2, md: 3 }}
        columns={{ xs: 4, sm: 8, md: 12 }}
    >
        <div className="flex bg-white rounded-md flex-col gap-4 px-8 py-4 h-max">
            <h1 className="font-bold text-xl">
                Class Group
            </h1>

            {
                user?.userType === "teacher" && <div>
                    <p className="font-bold">
                        Select Class
                    </p>
                    <Autocomplete
                        size="small"
                        {...GroupAutoCompleteProps}
                        value={group}
                        key={group?.id || "default-key"}
                        onChange={(_, value) => {
                            setGroup(value)
                        }}
                        getOptionLabel={(item) => {
                            return item.name
                        }}
                        isOptionEqualToValue={(option, value) =>
                            value === undefined || option.id === value.id
                        }
                        style={{ width: 300 }}
                        renderInput={(params) => (
                            <TextField {...params} variant="outlined" required />
                        )}
                    />
                </div>
            }
            <p className="font-bold">
                Select Group
            </p>

            <Autocomplete
                size="small"
                {...SubGroupAutoCompleteProps}
                value={subGroup}
                key={subGroup?.id || "default-key"}
                // disabled ={group?false:true}
                onChange={(_, value) => {
                    setSubGroup(value);
                }}
                getOptionLabel={(item) => {
                  
                    return `${item.name}`;
                }}
                isOptionEqualToValue={(option, value) =>
                    value === undefined || option.id === value.id
                }
                style={{ width: 300 }}
                renderInput={(params) => (
                    <TextField {...params} variant="outlined" required />
                )}
            />


            <Button variant="contained" style={{ alignSelf: "flex-start" }} onClick={() => {
                //  if (teacher?.id || !canShowTeacher?.can) {
                if(subGroup){
                    show("group", subGroup?.id || "me");
                    
                }else{
                    show("teachergroup", group?.id || "me");

                }
                    
                // mutate()
            }}>
                Generate
            </Button>
        </div>
        
    </Grid>
)
};
