"use client";

import { useList, useRouterContext, useShow } from "@pankod/refine-core";
import { useMemo, useState } from "react";
import {
  Box,
  Input,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableSortLabel,
} from "@pankod/refine-mui";
import { ExportAs } from "components/export";
import { visuallyHidden } from "@mui/utils";

type Res = {
  id: string;
  name: string;
  // firstName: string;
  // lastName: string;
  // uname: string;
  // grade: number;
  // school: {
  //   id: string;
  //   name: string;
  //   principalId: string;
  // };
  // progresses: [];
  logs: {
    wasCorrect: boolean;
    wcpm: number;
    textId: string;
    grade: number;
  }[];
};

interface Data {
  id: string;
  name: string;
  // firstName: string;
  // lastName: string;
  grade: number;
  p1: any;
  p2: any;
  p3: any;
  p4: any;
  p5: any;
  p6: any;
  p7: any;
  p8: any;
  p9: any;
  p10: any;
  // p1:{ wcpm: string; wasCorrect: boolean; }|undefined;
  // p2:{ wcpm: string; wasCorrect: boolean; }|undefined;
  // p3:{ wcpm: string; wasCorrect: boolean; }|undefined;
  // p4:{ wcpm: string; wasCorrect: boolean; }|undefined;
  // p5:{ wcpm: string; wasCorrect: boolean; }|undefined;
  // p6:{ wcpm: string; wasCorrect: boolean; }|undefined;
  // p7:{ wcpm: string; wasCorrect: boolean; }|undefined;
  // p8:{ wcpm: string; wasCorrect: boolean; }|undefined;
  // p9:{ wcpm: string; wasCorrect: boolean; }|undefined;
  // p10:{ wcpm: string; wasCorrect: boolean; }|undefined;

  // grade1: number | undefined;
  // grade2: number | undefined;
  // grade3: number | undefined;
  // grade4: number | undefined;
  // grade5: number | undefined;
  // grade6: number | undefined;
  // grade7: number | undefined;
  // grade8: number | undefined;
}

function descendingComparator<T>(a: T, b: T, orderBy: keyof T) {
  if (b[orderBy] === undefined || b[orderBy] === null) return -1;
  if (a[orderBy] === undefined || a[orderBy] === null) return 1;
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

type Order = "asc" | "desc";

function getComparator<Key extends keyof any>(
  order: Order,
  orderBy: Key
): (
  a: { [key in Key]: number | string | undefined },
  b: { [key in Key]: number | string | undefined }
) => number {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

interface HeadCell {
  id: keyof Data;
  label: string;
  numeric: boolean;
}

const headCells: readonly HeadCell[] = [
  {
    id: "name",
    label: "Full Name",
    numeric: false,
  },
  // {
  //   id: "lastName",
  //   label: "Last Name",
  //   numeric: false,
  // },
  // {
  //   id: "firstName",
  //   label: "First Name",
  //   numeric: false,
  // },
  {
    id: "grade",
    label: "Grade",
    numeric: true,
  },
  {
    id: "p1",
    label: "p-1",
    numeric: true,
  },
  {
    id: "p2",
    label: "p-2",
    numeric: true,
  },
  {
    id: "p3",
    label: "p-3",
    numeric: true,
  },
  {
    id: "p4",
    label: "p-4",
    numeric: true,
  },
  {
    id: "p5",
    label: "p-5",
    numeric: true,
  },
  {
    id: "p6",
    label: "p-6",
    numeric: true,
  },
  {
    id: "p7",
    label: "p-7",
    numeric: true,
  },
  {
    id: "p8",
    label: "p-8",
    numeric: true,
  },
  {
    id: "p9",
    label: "p-9",
    numeric: true,
  },
  {
    id: "p10",
    label: "p-10",
    numeric: true,
  },
  // {
  //   id: "grade1",
  //   label: "Grade 1",
  //   numeric: true,
  // },
  // {
  //   id: "grade2",
  //   label: "Grade 2",
  //   numeric: true,
  // },
  // {
  //   id: "grade3",
  //   label: "Grade 3",
  //   numeric: true,
  // },
  // {
  //   id: "grade4",
  //   label: "Grade 4",
  //   numeric: true,
  // },
  // {
  //   id: "grade5",
  //   label: "Grade 5",
  //   numeric: true,
  // },
  // {
  //   id: "grade6",
  //   label: "Grade 6",
  //   numeric: true,
  // },
  // {
  //   id: "grade7",
  //   label: "Grade 7",
  //   numeric: true,
  // },
  // {
  //   id: "grade8",
  //   label: "Grade 8",
  //   numeric: true,
  // },
];

interface EnhancedTableProps {
  onRequestSort: (
    event: React.MouseEvent<unknown>,
    property: keyof Data
  ) => void;
  order: Order;
  orderBy: string;
}

function EnhancedTableHead(props: EnhancedTableProps) {
  const { order, orderBy, onRequestSort } = props;
  const createSortHandler =
    (property: keyof Data) => (event: React.MouseEvent<unknown>) => {
      onRequestSort(event, property);
    };

  return (
    <TableHead>
      <TableRow>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={headCell.numeric ? "right" : "left"}
            padding="normal"
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : "asc"}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <Box component="span" sx={visuallyHidden}>
                  {order === "desc" ? "sorted descending" : "sorted ascending"}
                </Box>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

function stableSort<T>(
  array: readonly T[],
  comparator: (a: T, b: T) => number
) {
  const stabilizedThis = array.map((el, index) => [el, index] as [T, number]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

export const AssignableReportShow: React.FC = () => {
  const { useLocation } = useRouterContext();
  const { showId } = useShow();
  const location = useLocation();
  const lessonId = Number.parseInt(showId?.toString() ?? "0");

  const [order, setOrder] = useState<Order>("asc");
  // const [orderBy, setOrderBy] = useState<keyof Data>("lastName");
  const [orderBy, setOrderBy] = useState<keyof Data>("name");

  const handleRequestSort = (
    event: React.MouseEvent<unknown>,
    property: keyof Data
  ) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const [search, setSearch] = useState<string>("");

  const { data: unsortedData } = useList<Res>({
    config: {
      filters: [
        {
          field: "grade",
          operator: "eq",
          value: new URLSearchParams(location.search).get("grade"),
        },
        {
          field: "teacherId",
          operator: "eq",
          value:showId,
          // value: 'f31ef9cb-9e6b-4528-9f27-f2032de629e5',
        },
      ],
    },
    resource: `assignableCheckpoint/report`,
  });

function getData(logs,text) {
  const logEntry = logs.find((i) => i.textId === text);
  return logEntry 
    ? { wcpm: logEntry.wcpm, wasCorrect: logEntry.wasCorrect } 
    : undefined;
}
  const processedData: Data[] = useMemo(() => {
    return (
      unsortedData?.data?.map((row) => ({
        id: row.id,
        name: row.name,
        // firstName: row.firstName,
        // lastName: row.lastName,
        grade:parseInt( new URLSearchParams(location.search).get("grade")??"1",10),
        // wasCorrect: row.lo
        // grade:1,
        p1: getData(row.logs,"P1"),
        p2: getData(row.logs,"P2") ,
        p3: getData(row.logs,"P3") ,
        p4: getData(row.logs,"P4") ,
        p5: getData(row.logs,"P5") ,
        p6: getData(row.logs,"P6") ,
        p7: getData(row.logs,"P7") ,
        p8: getData(row.logs,"P8") ,
        p9: getData(row.logs,"P9") ,
        p10: getData(row.logs,"P10") ,
        // grade1: row.checkpoint.find((i) => i.textId === "G1")?.wcpm,
        // grade2: row.checkpoint.find((i) => i.textId === "G2")?.wcpm,
        // grade3: row.checkpoint.find((i) => i.textId === "G3")?.wcpm,
        // grade4: row.checkpoint.find((i) => i.textId === "G4")?.wcpm,
        // grade5: row.checkpoint.find((i) => i.textId === "G5")?.wcpm,
        // grade6: row.checkpoint.find((i) => i.textId === "G6")?.wcpm,
        // grade7: row.checkpoint.find((i) => i.textId === "G7")?.wcpm,
        // grade8: row.checkpoint.find((i) => i.textId === "G8")?.wcpm,
      })) || []
    );
  }, [unsortedData]);

  const visibleRows = useMemo(
    () =>
      stableSort(processedData, getComparator(order, orderBy)).filter((row) => {
        return (
          //@ts-ignore
          row.name?.toLowerCase().includes(search.toLowerCase())
          // row.firstName?.toLowerCase().includes(search.toLowerCase()) ||
          // //@ts-ignore
          // row.lastName?.toLowerCase().includes(search.toLowerCase())
        );
      }),
    [order, orderBy, processedData, search]
  );

  return (
    <div style={{ height: "100%", width: "100%" }}>
      <div className="flex items-center justify-end py-2">
        <ExportAs
          id="result"
          multiplePages
          getExportName={() => `${new Date().toLocaleDateString()}`}
          //@ts-ignore
          getRows={() => {
            return [
              [
                "Name",
                // "Last Name",
                // "First Name",
                "Grade",
                "P1",
                "P2",
                "P3",
                "P4",
                "P5",
                "P6",
                "P7",
                "P8",
                "P9",
                "P10",
                
                // "Grade 1",
                // "Grade 2",
                // "Grade 3",
                // "Grade 4",
                // "Grade 5",
                // "Grade 6",
                // "Grade 7",
                // "Grade 8",
              ],
              ...processedData.map((row) => {
                return [
                  row.name,
                  // row.lastName,
                  // row.firstName,
                  row.grade,
                  row.p1,
                  row.p2,
                  row.p3,
                  row.p4,
                  row.p5,
                  row.p6,
                  row.p7,
                  row.p8,
                  row.p9,
                  row.p10,
                  // row.grade1,
                  // row.grade2,
                  // row.grade3,
                  // row.grade4,
                  // row.grade5,
                  // row.grade6,
                  // row.grade7,
                  // row.grade8,
                ];
              }),
            ];
          }}
        />
      </div>
      <Input
        onChange={(e) => {
          setSearch(e.target.value);
        }}
        placeholder={"Type here to search"}
        style={{ margin: 10 }}
      />
      <div id="result">
        <TableContainer>
          <Table>
            <EnhancedTableHead
              order={order}
              orderBy={orderBy}
              onRequestSort={handleRequestSort}
            />
            <TableBody className="border border-black/20">
              {processedData.map((row) => (
                <TableRow key={row.id} className="divide-x divide-black/20">
                  
                  {/* <TableCell
                    style={{
                      fontWeight: "bold",
                    }}
                  >
                    {row.lastName}
                  </TableCell>
                  <TableCell
                    style={{
                      border: "1px solid rgb(0 0 0 / 0.2)",
                      fontWeight: "bold",
                    }}
                    className="font-bold"
                  >
                    {row.firstName}
                  </TableCell> */}
                  <TableCell
                    style={{
                      border: "1px solid rgb(0 0 0 / 0.2)",
                      fontWeight: "bold",
                    }}
                    className="font-bold"
                  >
                    {row.name}
                  </TableCell>
                  <TableCell
                    style={{
                      fontWeight: "bold",
                    }}
                  >
                    {row.grade}
                  </TableCell>
                  
                  <GradeCell wcpm={row.p1?.wcpm} wasCorrect={row.p1?.wasCorrect} />
                  <GradeCell wcpm={row.p2?.wcpm} wasCorrect={row.p2?.wasCorrect} />
                  <GradeCell wcpm={row.p3?.wcpm} wasCorrect={row.p3?.wasCorrect} />
                  <GradeCell wcpm={row.p4?.wcpm} wasCorrect={row.p4?.wasCorrect} />
                  <GradeCell wcpm={row.p5?.wcpm} wasCorrect={row.p5?.wasCorrect} />
                  <GradeCell wcpm={row.p6?.wcpm} wasCorrect={row.p6?.wasCorrect} />
                  <GradeCell wcpm={row.p7?.wcpm} wasCorrect={row.p7?.wasCorrect} />
                  <GradeCell wcpm={row.p8?.wcpm} wasCorrect={row.p8?.wasCorrect} />
                  <GradeCell wcpm={row.p9?.wcpm} wasCorrect={row.p9?.wasCorrect} />
                  <GradeCell wcpm={row.p10?.wcpm} wasCorrect={row.p10?.wasCorrect} />
                  {/* <GradeCell wcpm={row.grade1} grade={1} />
                  <GradeCell wcpm={row.grade2} grade={2} />
                  <GradeCell wcpm={row.grade3} grade={3} />
                  <GradeCell wcpm={row.grade4} grade={4} />
                  <GradeCell wcpm={row.grade5} grade={5} />
                  <GradeCell wcpm={row.grade6} grade={6} />
                  <GradeCell wcpm={row.grade7} grade={7} />
                  <GradeCell wcpm={row.grade8} grade={8} /> */}
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </div>
    </div>
  );
};

function getWcpmColor(grade: number, wcpm?: number) {
  if (!wcpm) return "#f0f0f0";
  const gradeWcpm: { [key: number]: number } = {
    1: 16,
    2: 59,
    3: 79,
    4: 95,
    5: 109,
    6: 116,
    7: 117,
    8: 118,
  };
  return wcpm < gradeWcpm[grade] ? "#D5BE0D" : "#2091EB";
}
function getWasCorrectColor(wcpm?: number, wasCorrect?: boolean) {
  if (!wcpm && wcpm!=0) return "#f0f0f0";
  // const gradeWcpm: { [key: number]: number } = {
  //   1: 16,
  //   2: 59,
  //   3: 79,
  //   4: 95,
  //   5: 109,
  //   6: 116,
  //   7: 117,
  //   8: 118,
  // };
  return wasCorrect ? "#008000" : "#D5BE0D";
}

function GradeCell({ wcpm, wasCorrect }: { wcpm?: number | string; wasCorrect: boolean|undefined }) {
  return (
    <TableCell
      style={{
        border: "1px solid rgb(0 0 0 / 0.2)",
        background: getWasCorrectColor( wcpm as number,wasCorrect),
        // background: wasCorrect?"#D5BE0D":"#2091EB",
        color: "white",
      }}
    >
      {wcpm}
    </TableCell>
  );
}
